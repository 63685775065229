import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionDandruff() {
	
	return (
		<Article
			title='Dandruff'
			takeaway_title='Measures to cure the dandruff'
			page_id='Solution - Dandruff'
			read_time='7 mins'
			author='Brij Bhushan'
			image1='/factors_dandruff.jpg'
			image1_description='Factors that contribute to dandruff'
			image2='/hair_follicle.jpg'
			image2_description='Anatomy of hair follicle'
			toggle_article='Blog'
			toggle_url='/article/blog_dandruff'
			related_article='Blog : Dandruff'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						Dandruff is a condition called Seborrhoeic Dermatitis. Unfortunately, it is often treated by products promoted in advertisements. They state that once you have dandruff, it can be only be managed through their products and can’t be treated. They say this because it suits their business. Most of the commercial products like anti-dandruff shampoos address only one of the causes of dandruff & its side effects often aggravates other causes. For example, use of ZPT in shampoos may curb the fungal growth but might cause inflammation & subsequently result in dryness on the scalp such that the dandruff returns soon. With this line of treatment, one has to use the shampoo regularly which is often touted as maintenance. <span className="super">[1], [2]</span>
					</p>
					<p>
						In order to cure the dandruff, it is important to understand all the causes of dandruff & the diet & lifestyle problems that trigger those conditions. These are:
						<ol className='article_list'>
							<li>Fungal growth on scalp</li>
    						<li>Dryness on the scalp</li>
    						<li>Poor immunity</li>
    						<li>Excess oil secretion on scalp</li>
    					</ol>
					</p>
					<p>
						To treat dandruff permanently, one has to address all these conditions, rather than just 1 or 2. In subsequent sections we will discuss all these conditions in detail. <span className="super">[1]</span>
					</p>
					<img src='/dandruff_diagram.jpg' alt="Root cause of Dandruff" />
					<h3 className='article_heading'>Fungal growth on the scalp</h3>
					<p>
						Malassezia globosa is a very common fungus to be found on human skin. Infection from this fungus is what triggers the dandruff. However, a healthy immune system can easily avoid infection from this fungus. Dandruff happens only when this fungus gets under skin through cracks of dry skin and the body gives an improper immune response. <span className="super">[1]</span>
					</p>
					<h3 className='article_heading'>Dryness on the scalp</h3>
					<p>
						Dryness on the skin is the first factor which provides opportunity to the fungus to get under the skin to cause infection, through its cracks. A natural cause for dryness is weather & few other environmental conditions. However, dryness can be aggravated because of high inflammation in the body. The causes of inflammation have been discussed in detail, in the article on <Link to='/article/soln_faceacne'>Acne</Link>. In the majority of cases, sugar & sugar-based products (like biscuits/cookies, cakes, soft drinks, ice-creams, chocolates, candies, sweets, etc.) are the root cause. Their consumption causes a condition called insulin resistance in the body. Insulin resistance eventually leads to inflammation and that triggers the dryness of the skin. <span className="super">[1], [3]</span>
					</p>
					<h3 className='article_heading'>Weak immunity</h3>
					<p>
						Weak immunity is often a result of lack of nutrition in the diet or lack of capability of the gut to absorb nutrition from food. For good nutrition, one should focus on having a good variety of fruits and vegetables in the diet. For good efficiency of the digestive system, one should focus on reducing sugar from the diet, chewing the food properly, reducing snacking between meals and sufficient exercise. Details of these factors are captured in the article on <Link to='/article/soln_obesity'>Obesity</Link>. <span className="super">[4]</span>
					</p>
					<p>
						Lack of exposure to sunlight results in low Vitamin D, which also contributes to weakness in immunity. Several issues like stress, liver problems, thyroid problems, which can cause hormonal imbalance can cause weak immunity. Similarly, there are few diseases like Parkinson Disease, Alzheimer's disease, mental retardation, spinal cord injury, which can also cause weakness of the immune system. A weak immune system results in improper immune response to the infection from Malassezia fungus, which results in dandruff. <span className="super">[1]</span>
					</p>
					<h3 className='article_heading'>Excess oil secretion on the scalp</h3>
					<p>
						Sebaceous glands are attached to hair follicles under the skin of the scalp. The oil that they secrete helps in maintaining the flexibility of the skin. The oil contains Omega-6 & Omega-9 fatty acids which stimulate skin growth. Excess of Omega-6 & Omega-9 fatty acids accompanied with weak immune response, can cause increased skin cell production on the scalp. This increased skin cell production is the direct cause of dandruff flakes. <span className="super">[1], [5]</span>
					</p>
					<p>
						Diet based on refined vegetable oils and stress are two major causes of the excess oil secretion. Refined vegetable oils refers to Canola oil, Soybean oil, Sunflower oil, Safflower oil, Corn oil, Rice bran oil & others. Therefore, cold-pressed oils like organic coconut oil, organic mustard oil & extra virgin olive oil are better choices. <span className="super">[6]</span>
					</p>
					<h3 className='article_heading'>The solution</h3>
					<p>
						To solve this problem one has to plug all the sources of the problem. Here is the list of the measures that one has to take to cure the dandruff:
						<ol className='article_list'>
							<li>Wash your scalp regularly to keep it clean from excess fungal growth</li>
    						<li>Give your hair & scalp exposure to sunlight for at least 5 mins a day. (This controls fungal growth without damaging your hair.)</li>
    						<li>Once in a week apply oil on hairs & keep it for at least 2 hrs before washing it. (This will help in controlling dryness of the scalp.)</li>
    						<li>Remove (or atleast slowly & gradually start reducing) sugar & sugar-based products from diet. (This will reduce inflammation of the scalp as well as improve immunity.)</li>
    						<li>Add fresh fruits & raw vegetables in the diet for a balanced nutrition. (This will also improve immunity.)</li>
    						<li>Stop cooking food with refined oils. Instead of that use Pure desi ghee (clarified butter), Butter, cold-pressed oils - like organic coconut oil, organic mustard oil & extra virgin olive oil. (This will help in controlling excess oil secretion on the scalp.)</li>
    					</ol>
					</p>
					<p>
						It is recommended that one should use a shampoo to wash hairs which is free from harsh chemicals & have preferably only natural ingredients. Similarly, use only natural hair oils. Cold-pressed organic coconut oil is one good choice.
					</p>
					<p>
						In addition to the measures listed about, eating probiotic food like curd can also help in curing dandruff. <span className="super">[8]</span> Apart from this, one should avoiding haircare products which contain harsh chemicals. To identify hair care products with harsh chemicals refer <Link to='/article/soln_haircare'>this</Link> article.
					</p>
					<p>
						PS: To learn about my journey through the dandruff condition & how finally I cured it through these changes, click <Link to='/article/blog_dandruff'>here</Link>.

					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Tongyu C. Wikramanayake, Luis J. Borda, Mariya Miteva, et al. Seborrheic dermatitis - Looking beyond Malassezia. Experimental Dermatology. July 16, 2019. 28(9):991-1001. <Link to='https://onlinelibrary.wiley.com/doi/10.1111/exd.14006'>https://onlinelibrary.wiley.com/doi/10.1111/exd.14006</Link>
						</li>
						<li>
							Grace Gallagher. How Pyrithione Zinc Is Used in Skin Care. Healthline. March 26, 2020. <Link to='https://www.healthline.com/health/pyrithione-zinc#What-is-pyrithione-zinc?'>https://www.healthline.com/health/pyrithione-zinc#What-is-pyrithione-zinc?</Link>
						</li>
						<li>
							Gloria González-Saldivar, René Rodríguez-Gutiérrez, Jorge Ocampo-Candiani, et al. Skin Manifestations of Insulin Resistance: From a Biochemical Stance to a Clinical Diagnosis and Management. Dermatology and Therapy (Heidelb). 2017 Mar; 7(1): 37–51. <Link to='https://doi.org/10.1007/s13555-016-0160-3'>https://doi.org/10.1007/s13555-016-0160-3</Link>
						</li>
						<li>
							K. R. Srikantha Murthy, Vagbhata’s Astanga Hrdayam, Volume-1, Chapter-10, Chowkhamba Krishnadas Academy, Varanasi, Reprint 2019.
						</li>
						<li>
							Mount Sinai. Omega-6 fatty acids. <Link to='https://www.mountsinai.org/health-library/supplement/omega-6-fatty-acids'>https://www.mountsinai.org/health-library/supplement/omega-6-fatty-acids</Link>
						</li>
						<li>
							Vishruta Suresh Biyani. Foods that can make skin oily. MedIndia, Diet & nutrition. Aug 5, 2016. <Link to='https://www.medindia.net/patients/lifestyleandwellness/foods-that-can-make-skin-oily.htm'>https://www.medindia.net/patients/lifestyleandwellness/foods-that-can-make-skin-oily.htm</Link>
						</li>
						<li>
							Kumari Archana, D.B. Vaghela. An Ayurvedic Approach in the Management of Darunaka (Seborrhoeic Dermatitis): A Case Study. International Journal of Health Sciences and Research
 Vol.10; Issue: 4; April 2020. <Link to='https://www.ijhsr.org/IJHSR_Vol.10_Issue.4_April2020/11.pdf'>https://www.ijhsr.org/IJHSR_Vol.10_Issue.4_April2020/11.pdf</Link>
						</li>
						<li>
							Reygagne P, Bastien P, Couavoux MP, et. al.. The positive benefit of Lactobacillus paracasei NCC2461 ST11 in healthy volunteers with moderate to severe dandruff. Beneficial Microbes. 2017 Oct 13;8(5):671-680. <Link to='https://doi.org/10.3920/BM2016.0144'>https://doi.org/10.3920/BM2016.0144</Link>
						</li>
					</ol>
				</div>
		</Article>
	);
}

const TakeawayData = [
	{
		takeaway: 'Wash your scalp regularly',
	},
	{
		takeaway: 'Give your hair & scalp exposure to sunlight for atleast 5 mins a day',
	},
	{
		takeaway: 'Once in a week apply oil on hairs',
	},
	{
		takeaway: 'Remove sugar & sugar-based products from diet',
	},
	{
		takeaway: 'Add fresh fruits & raw vegetables in the diet',
	},
	{
		takeaway: 'Stop cooking food with refined oils',
	},
];

const RelatedArticles = [
	{
		title: 'Blog - Dandruff',
		path: '/article/blog_dandruff',
	},
	{
		title: 'Solution - Face Acne',
		path: '/article/soln_faceacne',
	},
	{
		title: 'Solution - Obesity',
		path: '/article/soln_obesity',
	},
	{
		title: 'Solution - Haircare',
		path: '/article/soln_haircare',
	},
];

